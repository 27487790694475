@media screen and (max-width:1240px) {
    
    .container{
        margin: 0 20px;
    }
    .whyUsContainer{
        margin-top: 40px;
    }
    .training_section{
        margin-top: 30px;
    }
    .thinking_main-container{
        margin-top: 40px;
    }
    .single_trainer_header,
    .trainer_main_cont h2{
        margin-top: 30px;
    }
    .grid_4-8{
        margin-top: 30px;
    }
}
@media screen and (max-width:1030px) {
    .first_section-container{
        flex-direction: column;
    }
    .delay_column{
        flex-direction: row;
    }
    .delay_column img{
        width: 50%;
        height: auto;
    }
    
    nav{
        background-color: white;
        position: fixed;
        top: 0;
        left: -260px;
        height: 100vh;
        width: 260px;
        padding-top: 50px;
        
        transition: 0.6s;
        z-index: 990;
        overflow-y: auto;
    }
    nav ul {
        gap: 0;
    }
    nav ul li{
        /* padding-left: 30px; */
        border-bottom: 1px solid black;
       margin-left: 20px;
       margin-right: 20px;
       padding-bottom: 10px;
       padding-top: 10px;
    }
    nav ul li a{
        color: black !important;
    }
    nav .flex{
        flex-direction: column;
    }
    .openMenuBtn{
        display: inline-block;
    }
    #navCheck:checked ~ nav{
        left: 0;
    }
    #navCheck:checked ~ .openMenuBtn{
        display: none;
    }
    #navCheck:checked ~ .closeMenuBtn{
        display: inline-block;
    }
    #navCheck:checked ~ .nav_shadow{
        display: block;
    }

    .thinking_main-container{
        grid-template-columns: repeat(1, 1fr);
    }
    .about-container{
        flex-direction: column;
        margin-top: 30px;
    }
    .drop_nav{
        height: auto;
    }
    .sub-nav{
        display: block;
        width: 200px;
        background-color:white;
        box-shadow: none;
        position: relative;
        height: 0;
        overflow: hidden;
        padding: 0;
        transition: height 0.3s;
        overflow-y: auto;

    }
    .resp_logo{
        display: flex;
    }
   
    .drop_nav_head {
        display: flex;
        justify-content: space-between;
    }
    .drop_nav_head svg{
        display: inline;
    }
    .sub-nav li {
        padding-left: 0;
        margin-left: 10px;
        margin-right: 0;
    }
    .sub-nav li a{
        color: var(--black) !important;
        
    }
    /* .stickyNav {
        background-color: red;
    }
    .stickyNav li a{
        color: black !important;
    } */
}

@media screen and (max-width: 900px) {
    .single_training_header{
        grid-template-columns: repeat(1, 1fr);
    }
    .single_training_header img{
        width: 100% !important;
    }
    .grid_4-8{
        grid-template-columns: repeat(1, 1fr);
    }
    
    .thinking_slider-container{
        width: 95vw;
        margin-left: 0;
    }
    .thigings_text-box,
    .thinking_slider_card2{
        /* width: 89%; */
    }
}
@media screen and (max-width:760px) {
    .delay_column{
        flex-direction: column;
    }
    .delay_column img{
        width:100%;
        height: auto;
    }
    .footer-cont{
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
    .footer_right{
        align-items: center;
        text-align: center;
        margin-top: 40px;
    }
    .card-2-footer{
        display: none;
    }
    .events_card_footer span:nth-child(2){
        display: none;
    }
    .card_more_view{
        display: inline-block;
    }
    .events_card_footer .card_more_view{
        margin-top: 0;
    }
    .bg{
        height: auto;
    }
    .single_trainer_header{
        flex-direction: column;
    }
    .header_carousel_img{
        height: 42vh !important;
    }
    .header_title_main{
        font-size: 150%;
        padding-left: 20px;
        padding-right: 20px;
        top: 100px;
        margin-left: 0;
        max-width: 70%;
    }
    .explore_btn{
        bottom: 30px;
    }
    .thining_slider_inner_card{
        width: 98%;
    }
}
@media screen and (max-width:555px) {
    .header-img{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .grid-col-3{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .text-Box{
        text-align: center;
    }
    .text-Box h1{
        font-size: 170%;
    }
    .header-img{
        height: auto;
    }
    .thigings_text-box h2{
        font-size: 160%;
    }
    .training_return_inp_cont{
        width: 300px;
    }
    .header-title{
        font-size: 140%;
    }
    .blog_card-container{
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
    .about-container img{
        width: 90%;
    }
    .card-1 {
        border-bottom: 1px solid rgba(191, 191, 191, 0.602);
        border-radius: 0;
        padding-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
    }
    .card-1 img{
        display: none;
    }
    .grid-col-2{
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .thinking_main-container{
        padding: 10px;
    }

    .explore_btn{
        font-size: 80%;
        width: 190px;
        height: 35px;
        padding-top: 8px;
        left: calc(50% - 90px);
    }
    .explore_btn::after{
        left: 5px;
    }
    .trainer_cert{
        margin-top: 30px;
    }
    .single_training_header img{
        width: 100%;
    }

    .events_details_container{

        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    }
}