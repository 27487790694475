*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --primary:#0C45E6;
    --primary-2:#1DB9DD;
    --primary-3:#22D2EF;
    --primary-4:#22D5FF;
    --black: #000;
    --white: #fff;
    --green: #258E46;
    --light-blue: #4270FF1A;
    --gray: #0c140f;
    --body: #F6F8F5;
    --dark-gray:#5f6160;
    --red:#ff3115;
    --blue: #5136F2;
}

body{
    /* font-family: 'Roboto', sans-serif; */
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    font-size: 20px;
    /* background-color: var(--body); */
}

a{
    text-decoration: none;
    outline: none;
}
ul{
    background: none;
    background-color: none;
}
nav ul li{
    list-style: none;
}
ul li{
    background: none;
    background-color: none;
}
button,
input{
    font-family: 'Roboto', sans-serif;
    outline: none;
}

button{
    cursor: pointer;
    border: none;
}
.openMenuBtn,
.closeMenuBtn{
    display: none;
    cursor: pointer;
}
section{
    /* margin: 40px 0px; */
}
.container{
    max-width: 1200px;
    margin: 0 auto;
}

.items-center{
    align-items: center;
}
.justify-between{
    justify-content: space-between;
}
.justify-center{
    justify-content: center;
}
.flex{
    display: flex;
}
.column{
    flex-direction: column;
}
.row{
    flex-direction: row;
}
.bg-gray{
    background-color: rgb(236, 236, 236) !important;
}
.mt-5{
    margin-top: 50px;
}
.mt-3{
    margin-top: 30px;
}
.grid_4-8{
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 50px;

}
.grid-col-2{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
}
.contact_social_container{
    background-image: linear-gradient(250deg, #4e95ed 0%, #03228f 100%);
    border-radius: 6px;
    padding: 30px;
}
.justify-end{
    justify-content: end;
}
.bg{
    background-image: url('../img/testi-home7.jpg');
    height: 500px;
    border-radius: 10px;
}

/* **************HEADER STYLE ****************** */
header{
    padding-top: 20px;
    padding-bottom: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    position: absolute;
    z-index: 2;
    /* background-color: var(--blue); */
    width: 100%;
}
.react-multiple-carousel__arrow {
    z-index: 1;
}
.nav_shadow{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 880;
    display: none;
}
.logo{
    height: 60px;
}
.stickyNav a{
    color: var(--black);
}
nav ul{
    gap: 20px;
}
nav ul li{
    position: relative;
}
nav ul li a{
    color: var(--white);
    font-weight: 500;
    font-size: 80%;
    position: relative;
}
nav ul li a::after{
    content: "";
    position: absolute;
    height: 2px;
    background-color: var(--primary);
    width: 0%;
    bottom: -3px;
    left: 0;
    transition: 0.5s;
}
nav ul li a:hover::after{
    width: 70%;
}
.sub-nav{
    display: none;
    background-color: var(--white);
    position: absolute;
    width: 250px;
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 6px;
    padding: 10px;
    transition: 4s;
}
nav ul li:hover .sub-nav{
    display: block;
}
.drop_nav_head svg{
    display: none;
}
.resp_logo{
    margin-top: -20px;
    display: none;
    justify-content: center;
}
.resp_logo img{
    width: 90px;

}
.sub-nav li a{
    font-size: 14px;
    color: var(--black);
}
/* *******************FIRST SECTION ********************* */
#firstSection{
    background-color: var(--white);
}
.first_section-container{
    gap: 50px;
    padding-top: 40px;
}
.text-Box{
    margin-top: -40px;
}
.text-Box span{
    color: var(--white);
    font-weight: 500;
    font-size: 90%;
    margin-bottom: 7px;
}
.text-Box h1{
    font-size: 250%;
    font-weight: 700;
    margin-top: 10px;
    color: var(--white);
}
.text-Box p{
    color: var(--white);
    font-size: 90%;
    max-width: 450px;
    margin-top: 10px;
    font-weight: 400;
}

.text-Box a{
    display: inline-block;
    padding: 15px 25px;
    font-size: 85%;
    font-weight: 600;
    margin-top: 30px;
    border-radius: 6px;
    /* background-color: var(--primary); */
    /* color: var(--white); */
    border: 2px solid var(--white);
    background: none;
    color: var(--white);
    transition: 0.3s;
}
.text-Box a:hover{
    color: var(--blue);
    background-color: var(--white);
}
.header-img{
    height: 455px;
}


/* **************************SECOND SECTION***************** */
.whyUsContainer{
    /* background-color: var(--white);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 40px;
    position: relative;
    padding: 1px 27px 27px 27px; */
}
.full_screen-container{
    background-color: var(--primary);
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;

}
.section-header-2{
    text-align: center;
    font-size: 150%;
    color: var(--white);
    font-weight: 600;
}
.grid-2-mini{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
}
.grid-col-3{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    margin-top: 30px;
}
.grid-col-4{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 30px;
}
.card-1{
    /* background-color: var(--white); */
    border-radius: 16px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    /* background-color: #F8F8F8; */
    padding: 0 20px;
    gap: 10px;
    position: relative;
    cursor: pointer;
    gap: 20px;
}
/* .card-1:hover p{
    width: 100%;
    height: 100%;
    padding: 10px;
    color: var(--white);
} */
.card-1 img{
    min-width: 208px !important;
    max-height: 208px;
    height: auto;
    object-fit: contain;
}
.card-1 h3{
    margin-top: 10px;
    font-size: 90%;
    font-weight: 600;
    color: var(--black);
}
.card-1 p{
    font-size: 80%;
    
    color: var(--dark-gray);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-weight: 300;
    line-height: 20px;
}

.gap-5{
    gap: 5px;
}
.gap-20{
    gap:20px;
}

.blog_card-container{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
.blog_card-img{
    width: 100%;
    height: 280px;
    overflow: hidden;
    border-radius: 6px;

}
.blog_card-container img{
    width: 100%;
    height: 280px;
    transition: 0.2s;
    border-radius: 6px;
}
.blog_card-container img:hover{
    transform: scale(1.1);
}
.blog_card_date{
    display: flex;
    align-items: center;
    font-size: 70%;
    gap: 7px;
    font-weight: 500;
}
.blog_card_body h4{
    margin-top: 20px;
    color: var(--primary);
    font-size: 90%;
}
.blog_card_user{
    display: flex;
    font-size: 70%;
    gap: 8px;
    margin-top: 20px;
    align-items: center;
    color: var(--red);
    font-weight: 600;
}
.blog_card_user span{
    display: flex;
    align-items: center;
}

.blog_card_text{
    font-size: 70%;
    margin-top: 20px;
    color: var(--dark-gray);
    line-height: 22px;
}
.blog_card_btn{
    width: 150px;
    height: 40px;
    margin-top: 20px;
    background: none;
    border: 2px solid var(--red);
    color: var(--black);
    font-size: 70%;
    font-weight: 700;
    transition: 0.2s;
    display: inline-block;
    text-align: center;
    padding-top: 10px;
}
.blog_card_btn:hover{
    background-color: var(--red);
    color: var(--white);
}
/* ********************THIRD SECTION *************************** */
.section-header{
    text-align: center;
    font-size: 150%;
    color: var(--primary);
    font-weight: 600;
}

.card-2-carousel{
    display: block;
    /* min-width: 370px; */
    /* width: 380px; */
    margin: 0 10px;
}

.card-2{
    border: 1px solid rgba(239, 239, 239, 0.582);
    border-radius: 10px;
}
.card_more_view{
    padding: 8px 9px;
    margin-top: 20px;
    /* margin-left: auto; */
    display: none;
    background-color: var(--primary);
    border-radius: 6px;
    color: var(--white);
    font-weight: 600;
}
/* .card-2:hover .card-2-body{
    border: 2px solid var(--primary);
} */

.card-2-body{
    padding:0 25px 25px 25px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 11px 15px 30px 0px rgba(128, 137, 225, 0.1);
    transition: 0.5s;
    border: 2px solid var(--white);

}

.card-2-body .flex span{
    font-size: 70%;
    color: var(--white);
    background-color: var(--primary);
    padding: 8px 9px;

    border-radius: 5px;
    font-weight: 700;
    margin-top: 20px;
}
.card-2-body .flex b{
    color: var(--green);
    font-size: 100%;
}
.card-2-body h3{
    font-size: 90%;
    margin-top: 15px;
    color: var(--primary);
}

.card-2-header{
    width: 100%;
    height: 280px;
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0c46e668;
    margin-left: auto;
    /* margin-bottom: -110px; */
    position: relative;
    margin-right: 10px;
    border-radius: 6px 6px 0 0;

}
.card-2-header img{
    width: 100%;
    height: 100%;
    border-radius: 6px 6px 0 0;
    /* border-radius: 50%; */

}
.card-2-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    background-color: #F1F2FD;
    border-radius: 6px;
    padding: 8px;
}

.card-2-footer img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.card-2-footer h4{
    font-size: 70%;
    color: var(--primary);
}
.card-2-footer p{
    font-size: 65% !important;
    color: var(--dark-gray) !important;
    background-color: none !important;
    margin-top: 5px;
}
.card-2-footer b{
    font-size: 90%;
    color: var(--primary);
}
/* **************** FOURTH SECTION ******************* */
.grid-col-4{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.inp-cont{
    border: none;
    height: 40px;
    width: 100%;
}
.inp-cont input{
    width: 100%;
    height: 100%;
    text-indent: 5px;
    border: none;
    border-radius: 6px;
    color: var(--dark-gray);
    font-size: 70%;
}
.question-textarea{
    width: 100%;
    margin-top: 20px;
    height: 120px;
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 5px;
    color: var(--dark-gray);
    resize: none;
    font-size: 80%;
}
.question_send-Btn{
    background-color: var(--primary);
    padding: 8px 10px;
    border-radius: 6px;
    margin-top: 10px;
    color: var(--white);
    font-size: 70%;
    font-weight: 700;
    display: block;
}

.faq-img{
    height: 500px;
    width: 100%;
    max-width: 500px;
}
.faq_list-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.faq_card{
    background-color: var(--white);
    padding: 10px;
    border-radius: 10px;
    /* box-shadow: 0 0 8px rgba(128, 128, 128, 0.548); */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: 100%;
}
.faq-header{
    cursor: pointer;
}
.faq-header h3{
    color: var(--primary);
    font-size: 90%;
    font-weight: 600;
}
.faq-header button{
    background-color: var(--primary);
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--white);
    font-size: 100%;
    padding-top: 4px;
}
.faq-body{
    font-size: 70%;
    font-weight: 400;
    color: var(--dark-gray);
    margin-top: 10px;
}
/* *****************SECOND PAGE **************** */
.page-header{
    background-image: url('../img/header-img-2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 40vh;
}
.shadow{
    width: 100%;
    height: 40vh;
    background-color: #000000b6;
}
.header-title{
    color: var(--white);
    font-size: 230%;
    font-weight: 500;
    text-align: center;
}

.about-container{
    gap: 50px;
    margin-top: 100px;
}
.about-container img{
    max-width: 500px;
}
.about-container h2{
    color: var(--primary);
    font-size: 130%;
    font-weight: 500;
}
.about-container p{
    font-size: 75%;
    color: var(--dark-gray);
    line-height: 22px;
    font-weight: 400;
}

/* ******************* THINKING SECTION***************** */
.thinking_main-container{
    padding: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.thinking_slider-container{
    /* display: flex;
    overflow: hidden;
    border-radius: 10px; */
    width: 500px;
}
.thining_slider_inner_card{
    width: 100%;
}
.thining_slider_inner_card .thinking_slider_header{
   justify-content: center;
    display: flex;
}
.thining_slider_inner_card .thinking_slider_card p{
    text-align: center;
}
.thinking_slider-container2{
    display: flex;
    overflow: hidden;
    border-radius: 10px;
}
.thinking_slider_card{
    background-color: var(--white);
  
    width: 100%;
}
.thinking_slider_card2{
    padding: 40px;
    border-radius: 6px;
    background-color: var(--white);
    width: 100%;


}
.thinking_slider_header {
    gap: 20px;
}
.thinking_slider_card2 img,
.thinking_slider_header img{
    max-width: 70px !important;
    min-width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
}
.tranining_about_title{
    color: var(--primary);
    margin-bottom: 15px;
    font-size: 120%;
}
.thinking_slider_header h4{
    color: var(--black);
    font-weight: 500;
    font-size: 90%;
}
.thinking_slider_header span{
    font-size: 70%;
    color: var(--dark-gray);
}
.thinking_slider_card2 p,
.thinking_slider_card p{
    margin-top: 20px;
    font-size: 80%;
    color: var(--dark-gray);
    font-weight: 400;
    line-height: 22px;
}
.thigings_text-box{
    width: 100%;
}
.thigings_text-box h2{
    color: var(--white);
    font-weight: 600;
    font-size: 190%;
}

/* ********************** SINGLE TRAINING CONTAINER ******************* */

.single_training_header{
    margin-top: 50px;
    display: grid;
    grid-template-columns: 3fr 9fr;
    align-items: flex-start;
    gap: 40px;
}
.single_training_header img{
    width: auto;
    border-radius: 6px;
    height: 320px;
}

.training_details_container{
    width: 100%;
    /* display: flex;
    flex-direction: column; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    align-items: center;
    justify-content: center;
    /* margin-top: 20px; */
}
.events_details_container{
    width: 100%;
    /* display: flex;
    flex-direction: column; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.events_details_container h3{
    display: flex;
    align-items: center;
    gap: 7px;
}
.events_details_container .training_detail_card{
    height: 90px;
}
.active_training_box:hover span,
.active_training_box{
    transition: 0.3s;
}
.active_training_box:hover span,
.active_training_box:hover{
    background-color: var(--primary);
    color: var(--white) !important;
}
.training_detail_card{
    background-color: var(--white);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    border-radius: 6px;
    border-left: 6px solid var(--primary);
    display: flex;
    gap: 10px;
    position: relative;
    cursor: pointer;
    color: #000;
}


.training_detail_body h3{
    font-size: 100%;
    font-weight: 600;
}

.training_detail_body span{
    font-weight: 400;
    font-size: 80%;
    color: var(--dark-gray);
}
.training_detail_body a{
    font-weight: 400;
    font-size: 80%;
    color: var(--dark-gray);
}
.syllabus_card{
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

}
.single_training-subtitle{
    font-weight: 600;
    color: var(--primary);
    border-bottom: 2px solid gray;
    padding-bottom: 2px;
}

.single_training-text{
    font-size: 75%;
    color: var(--dark-gray);
    line-height: 22px;
    margin-top: 10px;
}

.training_data-body{
    background-color: var(--white);
    border-radius: 12px;
    padding: 10px;
    margin-top: 20px;
}
.inp_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
}
.inp_container input{
    width: 100%;
    height: 35px;
    border-radius: 6px;
    border: none;
    padding-left: 5px;
    color: var(--primary);
    font-size: 80%;
}
.inp_container input::placeholder{
    color: var(--primary);
}
.inp_container label{
    color: var(--white);
    font-size: 70%;
}

.apply_button{
    padding: 8px 9px;
    margin-top: 20px;
    border-radius: 6px;
    background: none;
    border: 2px solid var(--white);
    color: var(--white);
    font-size: 70%;
    transition: 0.2s;
}
.apply_button:hover{
    background-color: var(--primary);
    border: 2px solid var(--primary); 
}
.contact_title{
    color: var(--white);
    font-size: 80%;
}
.contact_subtitle{
    font-size: 150%;
    color: var(--white);
    margin-top: 16px;
}
.contact_socials{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.social-list{
    display: flex;
    align-items: center;
    gap: 15px;
}

.social_icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--white);
   
}
.dark{
     padding-top: 4px;
}
.social_title{
    color: var(--white);
    font-size: 85%;
}
.social_url{
    color: var(--white);
    font-size: 75%;
    margin-top: 10px;
}

.contact_inp_title{
    color: var(--black);
    font-size: 190%;
    text-align: center;
}

.contact_page-inp-cont{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    margin-top: 30px;
}

.contact_page-inp-cont input{
    background-color: var(--white);
    border: none;
    padding-left: 6px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid var(--primary);
    color: var(--dark-gray);
}

.contact_page_question_inp{
    margin-top: 30px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--primary);
    color: var(--dark-gray);
    padding: 6px;
    height: 150px;
    outline: none;
    font-size: 80%;
    font-weight: 300;
}

.contact_page_btn{
    background-image: linear-gradient(90deg, #03228f 0%, #4e95ed 100%);
    padding: 16px 40px 16px 40px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 40px;
    color: var(--white);
    margin-top: 30px;
    
}

.single_blog_img{
    width: 100%;
    margin-top: 30px;
    /* height: 350px; */
    border-radius: 6px;
    overflow: hidden;
}
.single_blog_img img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
    transition: 0.2s;

}

.single_blog_img img:hover{
    transform: scale(1.1);
}

.blog_data_title{
    margin-top: 20px;
    font-size: 90%;
}

.blog_data_text{
    margin-top: 10px;
    line-height: 22px;
    /* color: var(--dark-gray); */
    font-size: 80%;
    overflow-x: hidden;
}
.blog_data_text img{
    max-width: 100%;
    height: auto;
}
.blog_data_text ul,
.blog_data_text ol{
    margin-left: 20px;
}
.slider-btn_container{
    display: flex;
    gap: 40px;
    margin-top: 40px;
}

.slider_btn{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--white);
    background: none;
    padding-top: 5px;
}

/* ************** FOOTER ************ */
footer{
    background-color: var(--white);
    margin-top: 50px;
    border-top: 1px solid rgba(128, 128, 128, 0.59);

}

.footer-cont{
    padding: 40px 0;   
    justify-content: space-between;

}
.footer_logo{
    width: 80px;
} 

.footer_title{
    font-size: 170%;
}
.footer_nav{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}
.footer_nav li{
    list-style: none;
    font-size: 80%;
}
.footer_nav li a{
    color: var(--black);
}
.footer_desc{
    font-size: 75%;
    max-width: 300px;
    margin-top: 15px;
}
.footer-sub_tit{
    color: var(--primary);
    position: relative;
    display: inline-block;
}
.footer-sub_tit::after{
    content: "";
    position: absolute;
    height: 2px;
    width: 70%;
    background-color: var(--primary);
    left: 0;
    bottom: -4px;
    border-radius: 15px;
}
.dark{
    background-color: var(--primary);
}
.dark svg path{
    fill: var(--white);
}
.footer_right{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.copyRight{
    text-align: center;
    background-color: var(--primary);
    padding: 20px 0;
    font-size: 70%;
    color: var(--white);
}
.copyRight a{
    color: var(--white);
}

/* trainer card */

.trainer_card2,
.trainer_card{
    border: 2px solid rgba(203, 203, 203, 0.344);
    border-radius: 6px;
    padding: 10px;
    background-color: #F8F8F8;
    position: relative;
    cursor: pointer;
}

.trainer_card img{
    width: 100%;
    height: 250px;
}
.trainer_card2 img{
    width: 100%;
    height: 400px;
}

.trainer_card_footer{
    position: relative;
    /* background-color: var(--white); */
    /* bottom: 15px; */
    width: 100%;
    /* left: 10%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 10px; */
    padding-top: 5px;
    border-radius: 90% 90% 0 0;
    transition: 0.8s;
}
.trainer_card_footer2{
    position: absolute;
    background-color: var(--white);
    bottom: 14px;
    width: 80%;
    left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border-radius: 90% 90% 0 0;
    transition: 0.8s;
}
.trainer_card_footer h3{
    color: var(--primary);
    font-weight: 500;
    font-size: 100%;
 
    /* margin-top: 10px; */
}
.trainer_card_footer2 h3{
    color: var(--primary);
    font-weight: 500;
    font-size: 100%;
 
    margin-top: 10px;
}
.trainer_card_footer2 span{
    color: var(--dark-gray);
    font-size: 80%;
    margin-top: 10px;
}

.trainer_card2:hover .trainer_card_footer2{
    background-color: var(--primary);
}
.trainer_card2:hover .trainer_card_footer2 h3,
.trainer_card2:hover .trainer_card_footer2 span{
    color: var(--white);
}
.trainer_card2:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.training_question_card{
    background-color: var(--white);
    margin-bottom: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 5px;
}
.training_question_card_header{
    padding: 7px;
    cursor: pointer;
}
.training_question_card_header h3{
    font-size: 90%;
    color: var(--primary);
}
.training_question_card_header button{
    background: none;
    font-size: 100%;
    color: var(--primary);
}

.training_question_card p{
    /* color: var(--dark-gray);
    font-size: 70%;
    padding: 7px;
    font-weight: 300; */
    /* margin-left: 7px; */
}

.training_return_cont{
    position: fixed;
    bottom: 50px;
    /* bottom: 100px; */
    right: 30px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 20px;
}

.training_return_cont_btn{
    /* width: 50px; */
    height: 40px;
    border-radius: 6px;
    background-color: var(--primary);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    gap: 7px;
    padding: 0 10px;
    color: var(--white);
}
.training_return_inp_cont{
    background-color: var(--white);
    width: 340px;
    /* padding: 20px; */
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 0px;
    overflow: auto;
    transition: height 0.5s;
}
.training_return_inp_cont{

}
.training_return_inp_cont::-webkit-scrollbar {
    width: 7px;
  }
   
  .training_return_inp_cont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .training_return_inp_cont::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  } 
.student_return{
    /* overflow: auto; */
    /* background-color: red; */
}

.student_return .inp_container{
    background-color: var(--primary);
    padding: 4px;
    border-radius: 6px;
}

.student_return .apply_button{
    background-color: var(--primary);
}


/* single trainer */

.single_trainer_header{
    border: 1px solid var(--primary);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
}
.single_trainer_header img{
    width: 260px;
}
.single_trainer_header h2{
    color: var(--primary);
    font-size: 110%;
    font-weight: 500;
    margin-bottom: 30px;
}
.single_trainer_header p{
    color: var(--black);
    font-weight: 300;
    font-size: 78%;
    line-height: 20px;
}

.student_rtn_cont{
    max-width: 930px;
    margin: 0 auto;
    margin-top: 30px;
}
.control-dots .dot{
    background-color: var(--primary) !important;
}
#firstSection .dot{
    background-color: var(--white) !important;
}
.header_carousel_img{
    height: 100vh;
    position: relative;
}
.header_carousel_img img{
    height: 100%;
    width: 100%;
}
.header_title_main{
    position: absolute;
    color: var(--white);
    top: 150px;
    width: 100%;
    left: 0;
    text-align: left;
    margin-left: 100px;
    font-size: 330%;
    max-width: 550px;
}

.explore_btn{
    position: absolute;
    bottom: 100px;
    left: 0;
    background-color: var(--white);
    width: 250px;
    height: 45px;
    text-align: center;
    padding-top: 10px;
    color: var(--black);
    font-size: 100%;
    left: calc(50% - 125px);
    transition: .4s;
    font-weight: 600;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.explore_btn::after{
    content: '';
    position: absolute ;
    width: 2px;
    background-color: var(--black);
    height: 100%;
    left: 15px;
    top: 0;
    margin-right: 4px;
}
.explore_btn span{
    margin-left: 8px;
}
.alice-carousel__stage {
    /* display: flex !important;
    gap: 20px !important; */

}
.alice-carousel__wrapper{
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.alice-carousel__dots{
    display: none;
}
.item{
    margin: 0 20px;
}

.alice-carousel__prev-btn{
    background-color: rgba(255, 255, 255, 0.137);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    top: 0;
    position: absolute;
    margin-top: 200px;
}
.alice-carousel__prev-btn-item {
    color: var(--primary);
}
.alice-carousel__next-btn{
    background-color: rgba(255, 255, 255, 0.137);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    top: 0;
    position: absolute;
    margin-top: 200px;
    right: 0;
}



/* .react-multi-carousel-track {
    gap: 20px;
} */

.back_home_page{
    margin-top: 20px;
    background-color: var(--primary);
    color: var(--white);
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
}

.loading_shadow{
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading_logo{
    width: 100px;
}

.loading_text{
    color: var(--white);
    margin-top: 10px;
    font-size: 70%;
}

/* skeleton */

.skeleton {
    /* width: 280px; */
    display: block;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .12);
  }
  
  .skeleton-image {
    height: 150px;
  }
  
  .skeleton-image img {
    display: block;
    width: 100%;
    height: inherit;
    object-fit: cover;
  }
  
  .skeleton-content {
    padding: 2rem 1.8rem;
  }
  
  .skeleton-content  h4 {
    margin: 0 0 1rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  
  .skeleton-description {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  
   .skeleton-loading .skeleton-image,
   .skeleton-loading h4,
   .skeleton-loading .skeleton-description {
    background-color: #ededed;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 60%
    ) #ededed;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }
  
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
  
  .skeleton-loading h4 {
    min-height: 1.6rem;
    border-radius: 4px;
    animation-delay: .05s;
  }
  
  .skeleton-loading .skeleton-description {
    min-height: 4rem;
    border-radius: 4px;
    animation-delay: .06s;
  }
  
  .embed_parent iframe{
    width: 100%;
    height: 550px;
    border-radius: 20px;
  }
  
  .skeleton-text{
    width: 100%;
    height: 350px;
  }
  .skeleton-text h4{
    background-color: #ededed;
    width: 100%;
    height: 100px;
  }
  .skeleton-text div{
    background-color: #ededed;
    width: 100%;
    height: 20px;
    margin-top: 20px;
  }
  .skeleton-text h4,
  .skeleton-text div{
    background-color: #ededed;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 60%
    ) #ededed;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }
  .dangerous_div {
    margin-left: 7px;
  }
  .dangerous_div ul{
    margin-left: 40px;
  }
  .carousel-cont .events_card{
    margin: 20px;
  }
  .events_card{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 6px;
    color: #000;
    display: block;
    height: auto;
  }
  .events_card img{
    width: 100%;
    border-radius: 6px 6px 0 0;
    height: 280px;
  }

  .events_card_body{
    padding: 20px;
  }

  .events_card_body h2{
    font-size: 110%;
    font-weight: 500;
  }

  .events_card_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
  }
  .events_card_footer span{
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: var(--primary);
  }

  .show_all_events{
    display: flex;
    justify-content: center;
  }
  .show_all_events a{
    color: var(--black);
    font-weight: 500;
    display: inline-flex;
    align-items: center;
  }
  .show_all_events a svg{
    margin-top: -3px;
  }

  .event_info_box{
    border: 1px solid rgb(202, 202, 202);
    padding: 13px 9px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: 90%;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: white;
    height: 70px;
    color: var(--black);
  }



  /* wp apply */

  .wp_apply_btn{
    position: fixed;
    bottom: 110px;
    right: 30px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
    z-index: 5;
  }
  @media screen and (max-width: 500px) {
    .wp_apply_btn{
        z-index: 1;
    }
  }

  .wp_apply_cont{
    width: auto;
    height: auto;
    background-color: white;
    box-shadow: 0 0 5px gray;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }


  .wp-appy-img{
    width: 230px;
    border-radius: 6px;
  }
 
.apply-wp-btn{
    background-color: #25D366;
    color: white;
    padding: 9px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    font-size: 70%;
    font-weight: 600;
    border-radius: 8px;
}
  .wp-apply-text1{
    font-size: 70%; 
    text-align: center;
    margin-top: 5px;
    color: var(--black);
  }

  .wp_apply_close_btn{
    background: white;
    position: absolute;
    top: -15px;
    right: -12px;
    padding: 1px;
    /* width: 28px; */
    /* height: 28px; */
    display: inline-flex;
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 50%;
    
  }